<template>
    <div class="info_table" v-if="display.length > 0">
       <div  class="row">
           <div class="item" v-for="(item,index) in 6" :key="index">{{animalArr[index] }}</div>
           <div class="add"> </div>
           <div class="item">{{animalArr[6]}}</div>  
        </div>
        <div class="row">
           <div class="element" v-for="(item,index) in 6" :key="index">{{elementArr[index] }}</div>
           <div class="add"> </div>
           <div class="element">{{elementArr[6]}}</div>  
        </div>    
    </div>
</template>

<script>

export default {
    props: {
        display: Array,
        lotteryCode: String,
    },
    data() {
        return {
            lotteryTableHeader:{
                qxc:[
                    ["第一位","2"],
                    ["第二位","2"],
                    ["第三位","2"],
                    ["第四位","2"],
                    ["第五位","2"],
                    ["第六位","2"],
                    ["第七位","2"],
                ],
                pl5:[
                    ["万位","2"],
                    ["仟位","2"],
                    ["佰位","2"],
                    ["拾位","2"],
                    ["个位","2"],
                ],
                powerball:[
                    ["奇偶","1"],
                    ["和值","1"],
                    ["平均值","1"],
                    ["连号","1"],
                    ["首尾差","1"],
                    ["尾数和","1"],
                ],
                megamillions:[
                    ["奇偶","1"],
                    ["和值","1"],
                    ["平均值","1"],
                    ["连号","1"],
                    ["首尾差","1"],
                    ["尾数和","1"],
                ],
                pl3:[
                    ["佰拾和","3"],
                    ["佰个和","3"],
                    ["拾个和","3"],
                    ["拾个和","3"],
                ],
            },
            lotteryTableConfig:{
                qxc:{
                    headWidth: "105px",
                    bodyWidth: "51px",
                },
                pl5:{
                    headWidth: "105px",
                    bodyWidth: "51px",
                },
                powerball:{
                    headWidth: "121px",
                    bodyWidth: "121px",
                },
                megamillions:{
                    headWidth: "121px",
                    bodyWidth: "121px",
                },
                pl3:{
                    headWidth: "183px",
                    bodyWidth: "59px",
                },
                xglhc:{
                    headWidth: "105px",
                    bodyWidth: "105px",
                },
              kl8lhc:{
                headWidth: "105px",
                bodyWidth: "105px",
              },
                sgplhc:{
                    headWidth: "105px",
                    bodyWidth: "105px",
                },
            },
            animalArr:[],
            elementArr:[],
        }
    },
    watch: {
        display: {
            handler: function(value){
                if(value.length >= 14) {
                    this.setArr(value);
                }                
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        setArr(data) {
            //備份值
            let newData = Object.assign([], data);
            
            this.animalArr = newData.splice(0, 7);
            this.elementArr = newData;
        },
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
.info_table{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #565656;
    padding: unset;
    table-layout:fixed;
    margin-bottom: 5px;
    .row{
        display:grid;
        grid-template-columns: repeat(11, 1fr);
        grid-gap: 1px;
        .item{
            width: auto;
            height: auto;
            margin: 1px;
        }
        .add{
            margin-right: 15px;
        }
    }
    th{             
    }
    td{
        width: 20px;

    }
    .element{
        color: #df0d15;
    }
}
 
</style>