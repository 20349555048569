<template>
    <div class="home">
         <div class="banner">
            <div class="content">
               
            </div>
        </div>
        <div class="marquee">
                <div class="top_content">
                    <div class="notice">
                        <img class="icon" src="@/img/H5/volume.png">
                        <marquee class="info" direction="left">最新公告： 尊敬的用户，彩云资讯为您提供最专业、最优质的彩票信息。立即加入，成就您的梦想。</marquee>
                    </div>
                </div>
        </div>
      <div class='backup-domain'>
        <div class='domain-column'>
          备用域名1
          <a href='https://cy1881.net'>
            cy1881.net
          </a>
        </div>
        <div class='domain-column'>
          备用域名2
          <a href='https://cy1882.net'>
            cy1882.net
          </a>
        </div>
      </div>


        <div class="manu">
            <div class="row">
                    <div class="item" :class="selectCountry == 0 && 'active'" @click="selectCountry = 0">全部彩种</div>
                    <div class="item" :class="selectCountry == 1 && 'active'" @click="selectCountry = 1">全国彩</div>
                    <div class="item" :class="selectCountry == 5 && 'active'" @click="selectCountry = 5">英国天天彩</div>
                    <div class="item" :class="selectCountry == 2 && 'active'" @click="selectCountry = 2">英国彩</div>
                    <div class="item" :class="selectCountry == 3 && 'active'" @click="selectCountry = 3">澳洲彩</div>
                    <div class="item" :class="selectCountry == 4 && 'active'" @click="selectCountry = 4">极速彩</div>
            </div>
        </div>
        <div class="content">
             <LotteryHomeCard v-for="(item, key) in LotteryList" :key="key" :lotteryCode="key"  @openVedio="openVedio"/>
        </div>
        <div v-if="showVedio">
            <h5VideoLive @closeVedio="closeVedio" :lotteryType="clickVedioLotteryType" :lotteryInfo="clickVedioLotteryinfo"></h5VideoLive>
        </div>
    </div>
</template>
<script>
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import { mapState, mapGetters, mapActions } from 'vuex';
import LotteryHomeCard from "@/components/H5/Lottery_Home_Card.vue";
import h5VideoLive from "@/components/live_stream/h5VideoLive.vue";
export default {
    name: 'Home',
    components: {
        LotteryHomeCard,
        h5VideoLive,
    },
    data() {
        return {
            readMoreCount: 3,
            selectCountry: 0, //0:ALL  1:CN  2:GB  3:AU  4:急速 5: 英國天天彩
            homeLotteryConfig: lotteryConfig,
            showVedio: false,
            clickVedioLotteryType: '',
            clickVedioLotteryinfo: '',  
        }
    },
    computed: {
        ...mapState([
            "lotteryInfo"
        ]), 
        LotteryList:function(){

            if(this.selectCountry==0)
            {
                return  lotteryConfig;
            }
            if(this.selectCountry==1){

               return Object.filter(lotteryConfig, (item)=> item.country=='CN' );
            }
            if(this.selectCountry==2){
               
               return Object.filter(lotteryConfig, (item)=> item.country=='GB' );
            }
             if(this.selectCountry==3){
                
               return Object.filter(lotteryConfig, (item)=> item.country=='AU' );
            }
             if(this.selectCountry==4){
                
               return Object.filter(lotteryConfig, (item)=> !item.country);
            }
             if(this.selectCountry==5){
                
               return Object.filter(lotteryConfig, (item)=> item.country=='GBT' );
            }
           
        }
    },
    methods: {
        addReadMoreCount () {
            this.readMoreCount+=3;
        },
        removeReadMoreCount(){
            this.readMoreCount-=3;
            if(this.readMoreCount<=0){
                this.readMoreCount=3;
            }
        },
        changeCountry(e){
            this._lotteryUtil.changeLottery();
            this.selectCountry=e;
        },
        openVedio(lotteryCode, lotteryInfo) {
            if(lotteryCode == undefined || lotteryInfo == undefined || lotteryInfo.next_data == undefined){
                return false;
            } else {
                this.clickVedioLotteryType = lotteryCode;
                this.clickVedioLotteryinfo = lotteryInfo;
                this.showVedio = true;
            }
        },
        closeVedio() {
            this.showVedio = false;
        },
    },
     watch: {
        '$route': {
            handler: function(to, from) {
               this.currentHistoryName = to.params.name;
               this._lotteryUtil.changeLottery();
            },
            immediate: true
        } 
    },
    mounted(){

      
    }
}
</script>
<style scoped lang="scss">
    .home{
        padding-top: 50px;
        padding-bottom: 62px;
        .manu{
            .row{
                display: flex;
                height: 27px;
                justify-content: space-around;
                flex-flow: row;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                background-color: #ebebeb;
                border-bottom: 1px solid #cfcfcf;
                .item{
                    box-sizing: border-box;
                    font-size: 12px;
                    font-weight: bold;
                    color: #565656;
                    height: 28px;
                    flex: 1;
                    line-height: 28px;
                    &.active{
                        color: #0f2957;
                        background-color: #f5f4f4;
                        border-bottom: 2px solid #0f2957;
                    }
                }
            }
         }
         .content{
             padding: 10px;
         }
         .banner{
            width: 100%; 
            overflow: hidden;
            .content{
                width: 100%;
                height: 110px;
                 margin-bottom: 0;
                background: url("~@/img/H5/banner.jpg") no-repeat;
                background-size: cover;
                display: flex;
            }
        }
        .marquee {
            width: 100%;
            height: 20px;
            position: relative;
            overflow: hidden;
            background-color: #ebebeb;;
            border: unset;
            font-family: SourceHanSansCN;
            font-size: 10px;
            font-weight: bold;
            line-height: 20px;
            color: #000000;
            border-bottom: 1px solid #cfcfcf;
            .top_content {
                width: 100%;
                margin: auto;
                display: flex;
                justify-content: space-between;
                .notice {
                    display: flex;
                    align-items: center;
                    z-index: 1;
                    .info {
                        width: 90%;
                        margin-left: 0;
                    }
                    .icon{
                        margin-left: 10px;
                    }
                }
            }
            &:before, &:after{
                position:absolute;
                content:'';
                display:block;
                width:60px;
                height:100%;
                z-index:2;
                top:0;
            }
            &:before{
                left: 25px;
                background: linear-gradient(to left, rgba(235, 235, 235, 0) 29%, rgba(235, 235, 235, 0.8) 100%);
                           }
            &:after{
                right:-10px;
                background: linear-gradient(to left, rgb(235, 235, 235,0.9) 60%,rgba(235,235,235,0) 100%);
            }
        }

    }
    .backup-domain{
      background:#fe6918;
      color:#ffea00;
      display:flex;
      justify-content: space-around;
      height:30px;
      align-items: center;
      font-style: italic;
      font-weight:bold;
      .domain-column{
        position:relative;
        width:100%;
        &:first-child:after{
            position:absolute;
            content:'';
            display:block;
            top:-6px;
            width:1px;
            height:26px;
            background:#ffb58c;
            right:0;
            z-index:1;
          }
      }
      a{color:#fff;text-decoration: underline;}
    }
</style>