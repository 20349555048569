<template>
    <div class="lotteryHomeCard">
        <div class="content_top">
            <div class="left">
                    <div class="first_row">
                        <img class="icon" :src="require(`@/img/ballicon/${lotteryCode}_icon.png`)">
                    </div>
                    <div class="second_row">
                        {{lotteryConfig[lotteryCode].name}}
                    </div>
            </div>
            <div class="right">
                <div class="first_row">
                        <div class="nextperiod">             
                            <p>下期开奖: <span class="orange">{{nextperiod.period_no}}</span></p>
                        </div>
                        <div class="downcount" v-if="lotteryDrawTime[lotteryCode]>0">
                            <div class="time_block" v-if="lotteryConfig[lotteryCode].lotteryType==1">
                                {{lotteryDrawTime[lotteryCode] | reciprocalDay}}
                                <p>天</p>
                            </div>
                            <div class="time_block" v-if="lotteryConfig[lotteryCode].lotteryType==1">
                                {{lotteryDrawTime[lotteryCode] | reciprocalHour}}
                                <p>时</p>
                            </div>
                            <div class="time_block">
                                {{lotteryDrawTime[lotteryCode] | reciprocalMinute}}
                                <p>分</p>
                            </div>
                            <div class="time_block">
                                {{lotteryDrawTime[lotteryCode] | reciprocalSecond}}
                                <p>秒</p>
                            </div>
                        </div>
                        <div class="downcount" v-else> 
                            <div class="icon"><img class="rotating" src="@/img/H5/return-1.png">开奖中...</div>
                        </div>
                    
                        
                </div>
                <div class="second_row ">
                    
                        <div class="drawballs draw_number_row" v-show="lotteryCode !='xglhc' && lotteryCode !='sgplhc' && lotteryCode !='kl8lhc'">
                            <template v-if="lotteryCode !='lucky7daily'"><!--英國天天除外-->
                                <div :class="`${lotteryCode} ball ball-${parseInt(item)} ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `" v-for="(item,index) in lastDraw" :key="index">   
                                <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                                <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
                                    <span v-else>
                                        {{item}}
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="lastDraw[0] == ''">
                                    <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 6" :key="index+'_3'">
                                    <span> ? </span>
                                    </div>
                                    <div class="add" v-if="padLeftLottery.includes(lotteryCode)">+</div>
                                    <div :class="`${lotteryCode} ball ball-99`">
                                        <span> ? </span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div v-for="(item,index) in lastDraw.slice(0, 6)"
                                    :key="index"
                                    :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`">
                                    <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                                    </div>
                                    <div class="add" v-if="padLeftLottery.includes(lotteryCode)">+</div>
                                    <div :class="`${lotteryCode} ball ball-${lastDraw[lastDraw.length-1]}`">
                                    <span v-if="padLeftLottery.includes(lotteryCode)">{{lastDraw[lastDraw.length-1]  | padLeft(2)}}</span>
                                    </div>

                                    <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 7- lastDraw.length" :key="index+'_2'">
                                    <span> ? </span>
                                    </div>
                                </template>
                            </template>
                        </div> 
                        <!--- 六合系列 -->
                        <div v-if="lastDraw.length > 0" class="drawballs draw_number_row lh" v-show="lotteryCode==='xglhc' || lotteryCode==='sgplhc'|| lotteryCode==='kl8lhc'">
                            <div :class="`${lotteryCode} ball ball-${lastDraw[index]}`" v-for="(item,index) in 6" :key="index">{{lastDraw[index] | padLeft(2)}}</div>
                            <div class="add"> + </div>
                            <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`">{{lastDraw[6] | padLeft(2)}}</div>  
                        </div>
                        <!-- 5分彩 -->
                        
                        <LhcTable v-if="lotteryCode=='xglhc' || lotteryCode=='sgplhc'|| lotteryCode=='kl8lhc'" :display="tableData" :lotteryCode="lotteryCode" />
                        <DefaultTable v-else :display="detailData" :lotteryCode="lotteryCode" />

                </div>
            
            </div>
        </div>
        <div class="content_bottom">
            <div class="">
                    <div class="period_row">
                        <div v-if="lotteryConfig[lotteryCode].displayperiodType=='nationwide'">
                           第<span class="orange">{{currentperiod.period_no | checkDisplayPeriod(lotteryCode, currentperiod.period_no, lotteryCode)}}</span>期
                        </div>
                    </div>
              </div>
             <div class="bottom_row">
                  <!-- 圖紙圖庫按鈕-英國天天彩 -->
                 <div class="imageLibBtn"  v-if="lotteryCode=='lucky7daily'||lotteryCode=='xglhc'||lotteryCode=='kl8lhc'" style="align-items: center">
                    <a @click="()=>{this.$router.push({path:`/mobile/lottery/${lotteryCode}`,query:{currentTab:'imageLib'} })}">
                        <img src="@/img/H5/imageLib-button.png">
                    </a>
                </div>
                <a class="item" :class="{active: isActive}" @click="`${isActive=!isActive}`" :href="`/#/mobile/lottery/${lotteryCode}`">开奖历史</a>
                <!-- <a class="item" >开奖视频</a> -->
                <div @click="openLiveStream" class="VideoBtn" v-if="lotteryCode !='kl8lhc'">
                    <a class="item" :class="lotteryConfig[lotteryCode].liveStream ? '' : 'unActive'">开奖视频</a>
                </div>
               <a class='launch-kl8-website item' v-else href='https://www.cwl.gov.cn/ygkj/kjzb/' target='_blank' rel='noopener noreferrer'>
                 <img src='@/img/icon_lottery.png' /> 开奖验证
               </a>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import DefaultTable from '@/components/H5/default_home_table.vue'
import LhcTable from '@/components/H5/lhc_home_table.vue'
export default {
    name: 'lotteryHomeCard',
    props: {
        lotteryCode: String,
    },
    components: {
        LhcTable,
        DefaultTable
    },
    data() {
        return{
            nextperiod:{},
            currentperiod: {},
            lastDraw: [],
            lotteryConfig: lotteryConfig,
            isActive: false,
            tableData: [],
            detailData:[],
            padLeftLottery: ['azxy8','jisukuaile','sgplhc','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc','kl8lhc'],
            LotteryTwenty:['cannon20','azxy20'],
            padLeftLottery2: ['azxy10','azxy20','jisusaiche']

        }
    },
    computed: {
        ...mapState([
            "lotteryInfo",
            "lotteryDrawTime"
        ]),
    },
     watch: {
        lotteryInfo: {
            handler: function(value){
                
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        }
    },
    methods: {
        setLotteryInfo: function(lotteryCode) {
           
            if(!this.lotteryInfo[lotteryCode]) {
                return;
            }
            
            let lotteryInfo = this.lotteryInfo[lotteryCode];
            this.lotteryName = lotteryInfo.name; // 樂透名
            if(lotteryInfo) {
                // 當前資訊
                //debugger
                this.nextperiod = lotteryInfo.next_data; 

                // 上期資訊
                this.currentperiod = lotteryInfo.current_data; 
                this.lastDraw = this.currentperiod.draw.length>0 ?  this.$options.filters.splitDrawNumber(this.currentperiod.draw.split(","),lotteryCode) : [];
                this.tableData = this.currentperiod.analysis;         
                this.detailData=this.currentperiod.detail_analysis;
            }                
        },
        openLiveStream: function(){
            if(!this.lotteryConfig[this.lotteryCode].liveStream ){
                return false;
            } else {
                this.$emit('openVedio', this.lotteryCode, this.lotteryInfo[this.lotteryCode]);
            }
        }
    },
    mounted(){

        
    }
}
</script>
<style scoped lang="scss" >
    img{
       width: 0.6rem;
    }
    .orange{
        color: #da3231;
    }

.lotteryHomeCard{
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 0 0 10px 0;
    -webkit-box-shadow: 1px 1px 16px 1px rgba(0,0,0,0.15); 
    box-shadow: 1px 1px 16px 1px rgba(0,0,0,0.15);
    .content_top{
        display: flex;
    }
    .left{
        z-index: 1;
        position: relative;
        box-sizing: border-box;
        padding-top: 5px;
        width:  28%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 5px;
    
    .first_row{
        justify-content: center;
        font-size: 17px;
        display: flex;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        font-family: 'Microsoft Yahei';   
      
    }
    .second_row{
        margin: 8px 0 0 0;
        font-family: 'Microsoft Yahei';
        font-size: 12px;
        font-weight: bold;
        color: #565656;
    }
    .third_row{
        display: flex;
        width: auto;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        .time_block{
            box-sizing: border-box;
            color: #444;
            font-family: 'Open Sans';
            margin-right: .008rem;
            text-align: center;
            font-size: 12px;
                    p {
                        margin-top: 0.06rem;
                        font-family: SFPro;
                        font-size: 12px;
                        font-weight: 500;
                        text-align: center;
                        color: #adadad;
                    }
                }
        }
        
    }  
    .right{
        width: 72%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .first_row{
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            display: -webkit-flex;
            -webkit-align-items: center;
            width: 100%;
            .nextperiod{
                font-size: 12px;
                font-weight: bold;
                color: #565656;
            
            }  
            .downcount{
                display: flex;
                justify-content: space-around;
                color: #fff;
                font-size: 12px;
                padding: 5px 0px;
                background-color: #0f2957;
                width: 104px;
                height: auto;
                min-height: 26px;
                border-radius: 0 0 0 5px;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                p{
                    margin-top: 2px;
                }
                .icon{
                    display: flex;
                    align-items: center;
                    display: -webkit-flex;
                    -webkit-align-items: center;
                    img{
                        width: 16px;
                        height: 16px;
                    }
                }
               
            }
        }
        .second_row{
            .draw_number_row{

                align-items: center;
                -webkit-align-items: center;
                margin: 10px 0;
                display: grid;
                grid-template-columns: repeat(11, 1fr);
                grid-gap: 1px;
                .ball:nth-child(10) {
                    grid-column: span 2;
                }
                .ball{
                    grid-column: span 1;
                    
                }
            }

        }
        
    }    
    .content_bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 0 5px;

        .bottom_row{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .item{
                color: #727272;
                border: 1px solid #727272;
                padding: 6px 10px 5px;
                border-radius: 3px;
                margin-right: 10px;
                font-size: 12px;
                font-weight: bold;
                background-color: #fff;
                &.active{
                    color: #fff;
                    background-color: #0f2957;
                }
            }
            .imageLibBtn{
                align-items: center;
                display: flex;
                margin-right: 10px;
                img{
                    display:block;
                    width: 72px;
                }
            }
        }
    }    
}
    .period_row{
        font-size: 12px;
        font-weight: bold;
    }


    .line{
        width: 1px;
        height: .14rem;
        background: #cccc;
        line-height: 40px;
        margin: 0 .16rem;
    }
    .add{
        font-size: 14px;
        margin: 0 0.03rem;
        color: #646363;
    }

.ball-99{
    background: #505050
}
.VideoBtn{
    display: flex;
    .item{
        &.unActive{
            color: #FFFFFF !important;
            background: #E1E1E1 0% 0% no-repeat padding-box !important;
            border:1px solid #E1E1E1 !important;
        }
    }
}
.launch-kl8-website{
  background:#ec6931!important;color:#fff!important;border-color:#ec6931!important;
  display:flex!important;align-items:center;padding:4px 10px!important;
  img{width:13px;margin-right:4px;}
}
</style>